import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  Step,
  StepButton,
  Stepper,
  Typography,
} from '@mui/material';
import moment from 'moment';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicInfo from './BasicInfo';
import {
  addJob,
  deleteJobStub,
  getAgents,
  getFileType,
  getJobInfo,
  getPolicies,
  getTimestamp,
  getUncPath,
  getDefaultValue,
} from '../jobManagementActions';
import LoadingScreen from 'src/components/LoadingScreen';
import PolicyAndSchedule from './PolicyAndSchedule';
import FileSystem from './FileSystem';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import JobModal from './JobModal';
import { getGlobalConfiguration, getProxyPath } from 'src/pages/system-configuration/global-configuration/globalActions';
import { ERROR_ALERT } from 'src/redux/reducers/alertReducer';
import { getCloudProfile, getProfileInfo } from '../../volume-management/volumeActions';
import { GetDetails } from 'src/utils/fetchServices';
import { getCompanyInfo } from '../../company-list/companyActions';

export default function AddNewJob() {
  const { themeStretch } = useSettings();
  const params = useParams();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.jobManagementReducer.loading);
  const dispatch = useDispatch();
  const agents = useSelector((state) => state.jobManagementReducer.agents);
  const policies = useSelector((state) => state.jobManagementReducer.policies);
  const exclusion = useSelector((state) => state.jobManagementReducer.exclusion);
  const timeStamp = useSelector((state) => state.jobManagementReducer.timeStamp);
  const uncPath = useSelector((state) => state.jobManagementReducer.uncPath);
  const jobInfo = useSelector((state) => state.jobManagementReducer.jobInfo);
  const fileTypes = useSelector((state) => state.jobManagementReducer.fileTypes);
  const proxyPaths = useSelector((state) => state.globalReducer.proxyPaths);
  const cloudProfileData = useSelector((state) => state.volumeReducer.cloudProfile);
  const globalConfiguration = useSelector((state) => state.globalReducer.globalConfiguration);
  const [count, setCount] = useState(0);
  const [fileType, setFileType] = useState(null);
  const [createFlag, setCreateFlag] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [schedule, setSchedule] = useState(false);

  const [selectedProxyIds, setSelectedProxyIds] = useState([]);
  const [isLocal, setIsLocal] = useState(1);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [companyFilestoresId, setCompanyFilestoresId] = useState(null);
  const [companyFilestoresName, setCompanyFilestoresName] = useState(null);
  const [active, setActive] = useState(false);
  const [proxyIds, setProxyIds] = useState([]);
  const [policyid, setPolicyid] = useState(1);
  const [stagingJob, setStagingJob] = useState(0);
  const [disableProxy, setDisableProxy] = useState(false);
  const [firstProxyCheck, setFirstProxyCheck] = useState(false);
  const [remotePath, setRemotePath] = useState([]);
  const [detectServer, setDetectServer] = useState(false);
  const [cloudProfileId, setCloudProfileId] = useState(0);
  const [selectedStorage, setSelectedStorage] = useState('STANDARD');
  const [selectedStorageAzure, setSelectedStorageAzure] = useState('HOT');
  const [showStorage, setShowStorage] = useState(false);
  const [notValidUncPath, setNotValidUncPath] = useState(false);
  const [disableForRetention, setDisableForRetention] = useState(false);
  const [showRetention, setShowRetention] = useState(false);
  const [showLegalHolds, setShowLegalHolds] = useState(false);
  const [isSteponeModified, setSteponeModified] = useState(false);
  const [retentionData, setRetentionData] = useState({
    retentionMode: null,
    retentionUntilDate: null,
  });
  const [extractContentCheck, setExtractContentCheck] = useState(false);
  const companyTag = localStorage.getItem('companyTagLocal');
  const companyStates = useSelector((state) => state.companyReducer);

  useEffect(() => {
    if (fileType == 2 || fileType == 3 || fileType == 0 || fileType == 4) {
      GetDetails({ url: 'license' })
        .then((res) => {})
        .then((e) => {});
    }
    dispatch(getAgents({}));
    dispatch(getPolicies(1));
    dispatch(getPolicies(2));
    dispatch(getTimestamp());
    dispatch(getUncPath(companyTag));
    dispatch(getProxyPath());
    dispatch(getDefaultValue());
    dispatch(getCloudProfile(2, { page: 0, size: 100 }));
    dispatch(getGlobalConfiguration());
  }, []);

  const [filteredStoreOptions, setFilteredStoreOptions] = useState([1,2,3,4])
  useEffect(() => {
    if (count === 1) {
      setSteponeModified(true);
    }
  }, [count]);
  useEffect(() => {
    if (jobInfo) {
      if (jobInfo?.activeActionType) {
        setExtractContentCheck(
          jobInfo.activeActionType === 'WITHCONTENT'
            ? true
            : jobInfo.activeActionType === 'WITHOUTCONTENT'
            ? false
            : false
        );
        setValue(
          'extractContent',
          jobInfo.activeActionType === 'WITHCONTENT'
            ? true
            : jobInfo.activeActionType === 'WITHOUTCONTENT'
            ? false
            : false
        );
      } else {
        setExtractContentCheck(false);
        setValue('extractContent', false);
      }
    }
  }, [jobInfo]);
  const getApiData = async (option) => {
    try {
      // Make the GET request with Axios for each option
      
      const response = await GetDetails({ url: `file-stores/company/${companyId}/base/${option}` });
      return { option, data: response.data };  // Return the option and the data
    } catch (error) {
      // In case of an error, log it and return empty data
      console.error(`Error fetching data for option ${option}:`, error);
      return { option, data: [] }; // Return empty array if there's an error
    }
  };
  
  // Function to filter out options with empty results
  const filterDropdownOptions = async () => {
    // List of all options in dropdown A
    const options = [1, 2, 3, 4];
  
    // Use Promise.all to fetch data for all options concurrently
    const results = await Promise.all(options.map(option => getApiData(option)));
  
    // Filter out the options where the API result data is empty
    const filteredOptions = results.filter(result => result.data.length > 0);
  
    // Extract just the valid options (the ones with non-empty results)
    const validOptions = filteredOptions.map(result => result.option);
    setFilteredStoreOptions(validOptions);
  };
  useEffect(() => {
    dispatch(getFileType(fileType == 0 ? 1 : fileType));
  }, [fileType]);

  useEffect(() => {
    if (fileTypes && fileTypes.length) {
      let fileStoreList = fileTypes.filter((obj) => {
        return obj.writable == true;
      });
      if (fileStoreList.length == 1) {
        setValue('companyFilestoresId', fileStoreList[0].id);
      }
    }
  }, [fileTypes]);

  const types = [
    { value: 'ARCHIVE', label: 'Archive' },
    { value: 'STUB', label: 'Archive & Stub' },
    { value: 'WITHOUTSTUB', label: 'Archive & Delete' },
    { value: 'ANALYSIS', label: 'Analysis' },
    { value: 'INDEXING', label: 'Indexing' },
    // { value: 'EVALUATE', label: 'Evaluate' },
    { value: 'INGESTION', label: 'Ingestion' },
    // { value: 'DELETESTUB', label: 'Delete Stub' },
  ];
  const intervalData = [
    { value: 'Once', label: 'Once' },
    { value: 'Daily', label: 'Daily' },
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Four_Weekly', label: 'Four Weekly' },
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Quarterly', label: 'Quarterly (12 weekly)' },
    { value: 'Yearly', label: 'Yearly (52 weekly)' },
    { value: 'Custom', label: 'Custom' },
  ];

  const steps = ['Basic Information', 'File System', 'Policy & Schedule'];

  useEffect(() => {
    let { type, id } = params;
    filterDropdownOptions()
    if (id) {
      // User Edit
      dispatch(getJobInfo(params.id));
      setCreateFlag(false);
    } else {
      // Add
      setValue('fileSystemType', 'remote');
      setCreateFlag(true);
    }
  }, []);

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9\s\-_#@$%&*!,.]+$/, 'Invalid characters in Name')
      .trim()
      .matches(/^[a-zA-Z0-9\s\-#@$%&*!,.]+$/, 'Invalid characters in Name')
      .max(50, 'Name is too long')
      .required('Name is required'),
    policyName: yup.string(),
    uncPath: yup.string().matches(/^(\/|\\)[A-Za-z0-9À-ž-=_\/\\$&+,:;?@#|'<>.^*()%!{} ]+$/, 'UNC Path Should Be Valid'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    watch,
    setValue,
    trigger,
    clearErrors,
  } = useForm({
    // defaultValues: { selected: 0 },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });
  const companyId = localStorage.getItem('companyId');
  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyInfo(companyId));
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    if (params.id) {
      if (!isEmpty(jobInfo)) {
        jobInfo.execTimeStartDate = jobInfo.execTimeStartDate
          ? moment(jobInfo.execTimeStartDate).format('YYYY-MM-DDTHH:mm')
          : jobInfo.execTimeStartDate;
        setSchedule(jobInfo.scheduled);
        if (jobInfo.actionType == 'STUB') {
          jobInfo.showEnableProxyServer = true;
        }

        if (jobInfo.execTimeStartDate != '' && jobInfo.execTimeStartDate != null) {
          jobInfo.scheduled = true;
        }

        if (!jobInfo.processOfflineFiles) {
          jobInfo.processOfflineFiles = false;
        }
        if (jobInfo.activeJob) {
          setActive(jobInfo.activeJob);
        }
        if (jobInfo.createUrlFiles) {
          setFirstProxyCheck(true);
        }
        if (jobInfo.storageClass) {
          setSelectedStorage(jobInfo.storageClass);
          setSelectedStorageAzure(jobInfo.storageClass);
        }
        if (jobInfo.legalHold == false) {
          setShowLegalHolds(false);
        } else {
          setShowLegalHolds(true);
        }
        if (jobInfo.objectLockType == null) {
          setShowRetention(false);
        } else {
          setShowRetention(true);
        }
        if (
          jobInfo.retentionUntilDate ||
          jobInfo.retentionUntilDate == null ||
          jobInfo.retentionMode ||
          jobInfo.retentionMode == null
        ) {
          setRetentionData({ retentionUntilDate: jobInfo.retentionUntilDate, retentionMode: jobInfo.retentionMode });
        }

        setCompanyFilestoresId(jobInfo?.companyFilestoresId);
        setStagingJob(jobInfo?.stagingJob);

        reset(jobInfo);
      }
    }
  }, [jobInfo]);

  useEffect(() => {
    setValue('showEnableProxyServer', false);
    if (getValues('actionType') === 'STUB') setValue('showEnableProxyServer', true);
  }, [getValues('actionType')]);

  const handleFileStoreId = (data) => {
    if (
      data.actionType == 'ARCHIVE' ||
      data.actionType == 'WITHOUTSTUB' ||
      data.actionType == 'INGESTION' ||
      data.actionType == 'ACTIVEARCHIVE'
    ) {
      data.processOfflineFiles = true;
      // data.companyFilestoresId = 0;
      return data.companyFilestoresId;
    }
  };

  useEffect(() => {
    if (fileType === 3) {
      if (params.id && stagingJob != 3) {
        setSelectedStorageAzure('HOT');
      }
      // else if(!params.id){
      //   console.log("oppp");
      //   setSelectedStorageAzure("HOT")
      // }
      setShowStorage(true);
    } else {
      if (params.id) {
        setShowStorage(false);
      }
    }

    if (params.id && watch('companyFilestoresId')) {
      fileTypes?.length &&
        fileTypes.map((item, i) => {
          if (item.id === watch('companyFilestoresId')) {
            cloudProfileData?.length &&
              cloudProfileData.map((cloud, i) => {
                if (cloud.id === item.cloudProfileId) {
                  if (cloud.awsAccessUrl == 's3.amazonaws.com' && companyFilestoresId) {
                    setShowStorage(true);
                  } else {
                    setShowStorage(false);
                  }
                }
              });
          }
        });
    }
  }, [params.id, cloudProfileData, watch('companyFilestoresId'), fileTypes]);

  // useEffect(()=>{
  //   if(firstProxyCheck){
  //     setFirstProxyCheck(true)
  //     setValue('createUrlFiles',true)
  //     if(proxyIds && proxyIds.length > 0){
  //       setDisableProxy(false)
  //     }else{
  //       setDisableProxy(true)
  //     }
  //   }else{
  //     setValue('createUrlFiles',false)
  //     setDisableProxy(false)
  //     setFirstProxyCheck(false)
  //   }
  // },[firstProxyCheck,proxyIds])
  const getOrdinalSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const modulo = number % 100;
    return number + (suffixes[(modulo - 20) % 10] || suffixes[modulo] || suffixes[0]);
  }
  const onSubmit = (data) => {
    // const {executionWeekNumber, executionWeekDay, ...rest} = initialPayload;
    // const data = initialPayload.executionIntervalUnit === "Monthly" ? initialPayload : rest
    if(data.executionIntervalUnit === "Monthly"){
   
        const date = moment(getValues('execTimeStartDate'));
        // Get the week of the month
        const firstDayOfMonth = date.clone().startOf('month');
        const weekOfMonth = Math.ceil(date.diff(firstDayOfMonth, 'days', true) / 7);
        // Get the day of the week (e.g., Friday)
        const dayOfWeek = date.format('dddd'); // This will give us "Friday"
        // Let's get the nth occurrence of the weekday (e.g., 2nd Friday of the month)
        data.executionWeekDay = dayOfWeek;
        data.executionWeekNumber = weekOfMonth;
    }
    if (!data.actionType || !data.policyId || !data.agentId) {
      if (!data.actionType && !data.policyId && !data.agentId)
        dispatch({ type: ERROR_ALERT, payload: 'An Action , policy and an agent must be selected' });
      else if (!data.actionType && !data.policyId)
        dispatch({ type: ERROR_ALERT, payload: 'An Action and policy must be selected' });
      else if (!data.policyId && !data.agentId)
        dispatch({ type: ERROR_ALERT, payload: 'An Agent and policy must be selected' });
      else if (!data.actionType && !data.agentId)
        dispatch({ type: ERROR_ALERT, payload: 'An Action and agent must be selected' });
      else if (!data.actionType) dispatch({ type: ERROR_ALERT, payload: 'An Action must be selected' });
      else if (!data.policyId) dispatch({ type: ERROR_ALERT, payload: 'Policy must be selected' });
      else if (!data.agentId) dispatch({ type: ERROR_ALERT, payload: 'An Agent must be selected' });

      return;
    }
    data.activeActionType = extractContentCheck ? 'WITHCONTENT' : 'WITHOUTCONTENT';
    let companyId = localStorage.getItem('companyId');
    data.execTimeStartDate = data.execTimeStartDate
      ? moment(data.execTimeStartDate).format('MM/DD/YYYY HH:mm')
      : data.execTimeStartDate;
    data.execTimeStart = data.execTimeStart ? +moment(data.execTimeStartDate).format('x') : data.execTimeStart;
    data.scheduled = schedule;
    data.remoteLibType = data.fileSystemType === 'sharePoint' ? 'sharepoint' : 'JNQ';
    data.fileSystemType = data.fileSystemType === 'sharePoint' ? 'remote' : data.fileSystemType;
    // data.scheduled = data.scheduled == 1 ? true : false;
    data.activeJob = active;
    data.saveAndRun = true;
    
    let payload = {
      agentId: 1,
      archiveFromAnalysis: false,
      applyExclusionPolicy: data.applyExclusionPolicy || false,
      companyId: +companyId,
      processOfflineFiles: data.processOfflineFiles || false,
      companyFilestoresId: handleFileStoreId(data),
      companyFilestoresName: companyFilestoresName,
      createUrlFiles: false,
      // contentIndexingEnabled: extractContentCheck || false,
      exclusionPolicyId: data.exclusionPolicyId || 0,
      execTimeStartDate: moment(data.execTimeStartDate).format('MM/DD/YYYY HH:mm') || null,
      executionInterval: 0,
      executionIntervalUnit: data.executionIntervalUnit || 'Once',
      extractContent: true,
      id: 0,
      localFSPath: '',
      maxAllowedHrs: 0,
      policyId: 0,
      processArchive: data.processArchive || false,
      processHidden: data.processHidden || false,
      processReadOnly: data.processReadOnly || false,
      proxyStubServerIds: [],
      readyToExecute: false,
      stagingJob: +fileType || 0,
      stagingPath: '',
      syncMode: '',
      tags: '',
      remoteLibType: data.remoteLibType || 'JNQ',
      lastRepoAccessedDate: 0,
      uncPath: '',
      jobName: '',
      deduplicatedVolume: false,
      proxyStubServer: '',
      legalHold: showLegalHolds,
      objectLockType: showRetention ? 'RETENTION' : null,
      retentionMode: retentionData.retentionMode,
      retentionUntilDate: retentionData.retentionUntilDate,

      ...data,
    };
    // const updatePayload = {
    //   actionType: data.actionType,
    //   activeActionType: data.activeActionType,
    //   activeJob: data.activeJob,
    //   agentId: data.agentId,
    //   applyExclusionPolicy: data.applyExclusionPolicy,
    //   archiveFromAnalysis: data.archiveFromAnalysis,
    //   checkDeDuplication: data.checkDeDuplication,
    //   companyFilestoresId: handleFileStoreId(data),
    //   companyId: +companyId,
    //   createUrlFiles: data.createUrlFiles,
    //   description: data.description,
    //   exclusionPolicyId: data.exclusionPolicyId,
    //   execTimeStartDate: data.execTimeStartDate || null,
    //   executionInterval: data.executionInterval || 0,
    //   executionIntervalUnit: data.executionIntervalUnit || 'Once',
    //   extractContent: data.extractContent,
    //   fileSystemType: data.fileSystemType,
    //   id: data.id,
    //   localFSPath: data.localFSPath || null,
    //   localFSPathExList: data.localFSPathExList || [],
    //   localFSPathsList: data.localFSPathsList || [],
    //   maxAllowedHrs: data.maxAllowedHrs || 0,
    //   name: data.name,
    //   policyId: data.policyId,
    //   processArchive: data.processArchive || false,
    //   processHidden: data.processHidden || false,
    //   processReadOnly: data.processReadOnly || false,
    //   processOfflineFiles: data.processOfflineFiles,
    //   proxyStubServerIds: data.proxyStubServerIds || [],
    //   readyToExecute: data.readyToExecute,
    //   remoteDomainName: data.remoteDomainName,
    //   remoteExPathsList: data.remoteExPathsList || [],
    //   remoteLibType: data.remoteLibType,
    //   remotePathsList: data.remotePathsList || [],
    //   remoteUserName: data.remoteUserName,
    //   remoteUserPassword: data.remoteUserPassword,
    //   saveAndRun: data.saveAndRun,
    //   scheduled: data.scheduled,
    //   stagingJob: +data.fileType || 0,
    //   stagingPath: data.stagingPath || '',
    //   syncMode: data.syncMode,
    //   tags: data.tags || '',
    // };
    if (policies.length > 0) {
      let selectedPolicy = policies.filter((policy) => policy.name == data.policyName);
      if (selectedPolicy.length > 0) {
        payload.policyId = selectedPolicy[0].id;
        data.policyId = selectedPolicy[0].id;
      }
    }

    if (data.actionType === 'DELETESTUB') {
      dispatch(deleteJobStub(payload, navigate));
    } else {
      if (params.id) {
        let updateData = {
          ...data,
          createUrlFiles: watch('createUrlFiles'),
          stagingJob: +fileType || 0,
          policyId: policyid || 1,
          companyFilestoresName: companyFilestoresName,
          companyFilestoresId: +companyFilestoresId,
          proxyStubServerIds: proxyIds,
          legalHold: showLegalHolds,
          objectLockType: showRetention ? 'RETENTION' : null,
          retentionMode: retentionData.retentionMode,
          retentionUntilDate: retentionData.retentionUntilDate,
        };
        if (showStorage) {
          updateData.storageClass = fileType === 3 ? selectedStorageAzure : selectedStorage;
        }
        // if (!updateData.extractContent) {
        //   updateData.activeActionType = ''
        // }
        dispatch(addJob(updateData, '', 'PUT', navigate));
      } else {
        if (showStorage) {
          let addJobData = {
            ...payload,
            proxyStubServerIds: proxyIds,
            createUrlFiles: watch('createUrlFiles'),
            storageClass: fileType === 3 ? selectedStorageAzure : selectedStorage,
          };
          dispatch(
            addJob(
              {
                ...addJobData,
              },
              '',
              '',
              navigate
            )
          );
        } else {
          dispatch(
            addJob(
              { ...payload, proxyStubServerIds: proxyIds, createUrlFiles: watch('createUrlFiles') },
              '',
              '',
              navigate
            )
          );
        }
      }
    }
    // setOpenModal(false);
  };

  useEffect(() => {
    if (agents && agents.content.length == 1) {
      // setValue('selected', agents.content[0].id);
      setValue('agentId', agents.content[0].id);
    }
  }, [agents]);

  useEffect(() => {
    if (policies && policies?.length == 1) {
      setValue('policyId', policies[0].id);
    }
  }, [policies]);

  useEffect(() => {
    if (remotePath?.length) {
      const detectPath = remotePath.some((item, i) => {
        const itemData = item.split('\\')[2];
        return uncPath.some((path, i) => itemData?.toLowerCase() === path?.toLowerCase());
      });
      setDetectServer(detectPath);
    }
  }, [remotePath]);

  useEffect(() => {
    if (detectServer && fileType != 1) {
      setValue('createUrlFiles', false);
      setProxyIds([]);
    }
    // if(fileType === 3){
    //   if(!params.id){
    //     setSelectedStorageAzure("HOT")
    //   }
    // }
  }, [fileType, detectServer]);

  useEffect(() => {
    if (showRetention == true) {
      if (!fileType == 3 && retentionData.retentionMode == null) {
        setDisableForRetention(true);
      } else if (retentionData.retentionUntilDate == null) {
        setDisableForRetention(true);
      } else {
        setDisableForRetention(false);
      }
    } else {
      setDisableForRetention(false);
    }
  }, [showRetention, retentionData.retentionMode, retentionData.retentionUntilDate]);

  const renderStep = () => {
    if (count === 0) {
      return (
        <BasicInfo
          setCount={setCount}
          types={types}
          agents={agents}
          register={register}
          errors={errors}
          watch={watch}
          getValues={getValues}
          proxyPaths={proxyPaths}
          setValue={setValue}
          setFileType={setFileType}
          setIsLocal={setIsLocal}
          jobInfo={jobInfo}
          proxy={[proxyIds, setProxyIds]}
          proxyCheck={[firstProxyCheck, setFirstProxyCheck]}
        />
      );
    }
    if (count === 1) {
      return watch('actionType') !== 'DELETESTUB' ? (
        <FileSystem
          setCount={setCount}
          isSteponeModified={isSteponeModified}
          uncPath={uncPath}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          createFlag={createFlag}
          ValidUncPath={[notValidUncPath, setNotValidUncPath]}
          remotePathDetails={[remotePath, setRemotePath]}
        />
      ) : null;
    }
    if (count === 2 && policies.length) {
      return (
        <PolicyAndSchedule
          setCount={setCount}
          types={types}
          policies={policies}
          exclusion={exclusion}
          intervalData={intervalData}
          timeStamp={timeStamp}
          fileTypeState={[fileType, setFileType]}
          fileTypes={fileTypes}
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
          isLocal={isLocal}
          getValues={getValues}
          schedules={[schedule, setSchedule]}
          submit={handleSubmit(onSubmit)}
          policy={[policyid, setPolicyid]}
          disableButton={[disableUpdate, setDisableUpdate]}
          fileStoreName={[companyFilestoresName, setCompanyFilestoresName]}
          fileStoreId={[companyFilestoresId, setCompanyFilestoresId]}
          activeStatus={[active, setActive]}
          params={params}
          stagingJob={stagingJob}
          proxyCheck={[firstProxyCheck, setFirstProxyCheck]}
          proxyPaths={proxyPaths}
          proxy={[proxyIds, setProxyIds]}
          detectServer={detectServer}
          cloudProfileDetails={[cloudProfileId, setCloudProfileId]}
          storageClasses={[selectedStorage, setSelectedStorage]}
          storageClassesAzure={[selectedStorageAzure, setSelectedStorageAzure]}
          showClassStorage={[showStorage, setShowStorage]}
          retentionShowData={[showRetention, setShowRetention]}
          legalHoldData={[showLegalHolds, setShowLegalHolds]}
          retentionBody={[retentionData, setRetentionData]}
          extractContentCheckState={[extractContentCheck, setExtractContentCheck]}
          companyStates={companyStates}
          filteredStoreOptions={filteredStoreOptions}
        />
      );
    }
  };

  const handleModal = () => {
    setOpenModal(true);
  };
  const name = watch('name');
  useEffect(() => {
    if (name && errors.name) {
      if (trigger(['name'])) {
        clearErrors('name');
      }
    }
  }, [clearErrors, trigger, name, errors]);
  const checkSteps = async () => {
    if (
      count == 1 &&
      !(watch('remotePathsList').length || watch('localFSPathsList').length || watch('sharePointPathsList').length)
    ) {
      dispatch({
        type: ERROR_ALERT,
        payload: 'No valid path is added to the list please add a valid path.',
      });
    } else {
      let isValid = true;
      switch (count) {
        case 0:
          isValid = await trigger(['name']);
          break;
        default:
          isValid = true;
      }
      if (isValid) {
        setCount(count + 1);
      }
    }
  };

  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <JobModal
          openModal={openModal}
          close={() => setOpenModal(false)}
          watch={watch}
          onSubmit={onSubmit}
          policies={policies}
          agents={agents}
          fileTypes={fileTypes}
          getValues={getValues}
          register={register}
          errors={errors}
          fileTypeState={[fileType, setFileType]}
          submit={handleSubmit(onSubmit)}
          selectedStorage={selectedStorage}
          selectedStorageAzure={selectedStorageAzure}
          showStorage={showStorage}
          proxyIds={proxyIds}
          showRetention={showRetention}
          retentionBody={[retentionData, setRetentionData]}
        />
        <Card>
          <CardHeader title={createFlag ? 'Add new Job' : 'Edit Job'} sx={{ mb: '20px' }} />
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} style={{ paddingTop: '1px' }}>
                <Stepper alternativeLabel activeStep={count}>
                  {steps.map((label, i) => (
                    <Step key={label}>
                      <StepButton color="inherit" onClick={() => setCount(i)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={12} md={12}>
                {!loading ? renderStep() : <LoadingScreen />}
              </Grid>
              {!(watch('actionType') === 'DELETESTUB' && count === 2) && (
                <Grid item xs={10} md={5} sx={{ ml: '80px', mb: '40px' }}>
                  {count > 0 && (
                    <Button variant="contained" sx={{ color: '#ffff', m: '10px' }} onClick={() => setCount(count - 1)}>
                      Back
                    </Button>
                  )}
                  {count < 2 && policies.length ? (
                    <Button
                      disabled={disableProxy || (count > 0 && watch('remoteUserPassword')?.includes('@'))}
                      variant="contained"
                      sx={{ color: '#ffff', m: '10px' }}
                      onClick={checkSteps}
                    >
                      Next
                    </Button>
                  ) : null}
                  {count === 2 && (
                    <Button
                      type="button"
                      onClick={handleModal}
                      variant="contained"
                      sx={{ color: '#ffff', m: '10px' }}
                      disabled={
                        // watch('createUrlFiles') && fileType !== 1 && !proxyIds.length ||
                        disableUpdate ||
                        ((watch('actionType') == 'STUB' ||
                          watch('actionType') == 'WITHOUTSTUB' ||
                          watch('actionType') == 'ARCHIVE' ||
                          watch('actionType') == 'INGESTION' ||
                          watch('actionType') == 'ACTIVEARCHIVE') &&
                          !companyFilestoresId &&
                          watch('stagingPath') !== 4 &&
                          !watch('stagingPath')) ||
                        (watch('createUrlFiles') && fileType !== 4 && !proxyIds.length) ||
                        disableForRetention
                      }
                    >
                      {createFlag ? 'Submit' : 'Update'}
                    </Button>
                  )}
                  <Link to="/dashboard/job-list" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" sx={{ color: '#ffff', m: '10px' }}>
                      Cancel
                    </Button>
                  </Link>
                </Grid>
              )}
            </Grid>
          </form>
        </Card>
      </Container>
    </Page>
  );
}
