import { sentenceCase } from 'change-case';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
  TablePagination,
  Stack,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import Scrollbar from '../../../../components/Scrollbar';
import Label from 'src/components/Label';
import { useEffect, useState } from 'react';
import DetailsModal from './DetailsModal';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import moment from 'moment';
import { styled } from '@mui/styles';

export default function JobTable({ pages, size, jobs, columns, jobRerun, reload, sortData }) {
  const theme = useTheme();
  const [rowsPerPage, setRowsPerPage] = size;
  const [page, setPage] = pages;
  const [open, setOpen] = useState(false);
  const [jobDetails, setJobDetails] = useState();
  const [refresh, setRefresh] = reload;
  const [sort, setSort] = sortData;
  const [arrow, setArrow] = useState(false);
  const [active, setActive] = useState();

  const showTarget = (row) => {
    if (
      row.actionType === 'ARCHIVE' ||
      row.actionType === 'STUB' ||
      row.actionType === 'WITHOUTSTUB' ||
      row.actionType === 'ACTIVEARCHIVE'
    ) {
      if (row.stagingJob === 0) return 'Repository';
      // else if (row.stagingJob === 1) return 'Stagging Path';
      else if (row.stagingJob === 4) return 'SMB/CIFS';
      else if (row.stagingJob === 2) return 'S3 Cloud';
      else if (row.stagingJob === 3) return 'Azure Cloud Blob';
    } else {
      return 'NA';
    }
  };

  const statusColor = (row) => {
    if (sentenceCase(row.jobStatus) === 'Completed') return 'success';
    else return 'warning';
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const handleOpenModal = (row) => {
    setJobDetails(row);
    setOpen(true);
  };

  const TableSort = (i) => {
    setActive(i);
    setArrow(!arrow);
    if (i == 0) {
      if (!arrow) {
        setSort('name,desc');
      } else {
        setSort('name,asc');
      }
    }
    if (i == 1) {
      if (!arrow) {
        setSort('actionType,desc');
      } else {
        setSort('actionType,asc');
      }
    }
    if (i == 2) {
      if (!arrow) {
        setSort('agent,desc');
      } else {
        setSort('agent,asc');
      }
    }
    if (i == 3) {
      if (!arrow) {
        setSort('policy,desc');
      } else {
        setSort('policy,asc');
      }
    }
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} enterDelay={600} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: 13,
      fontWeight: 400,
      maxWidth: 'none',
    },
  }));

  return (
    <>
      <Card>
        <DetailsModal modalPopUp={[open, setOpen]} jobDetails={jobDetails} jobRerun={jobRerun} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="Job Status" sx={{ mb: 3 }} />
          <Button
            variant="contained"
            sx={{ color: '#ffff', height: '40px', m: '20px' }}
            onClick={() => setRefresh(!refresh)}
          >
            Refresh
          </Button>
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {columns.map((column, i) => (
                    <TableCell key={i}>
                      <Stack direction="row" onClick={() => TableSort(i)}>
                        <Typography style={{ marginRight: '8px' }}> {column}</Typography>
                        {i < 4 && active == i ? (
                          arrow == true ? (
                            <ArrowDownwardIcon />
                          ) : (
                            <ArrowUpwardIcon />
                          )
                        ) : i < 4 ? (
                          <ArrowUpwardIcon size="small" sx={{ opacity: '0', '&:hover': { opacity: '0.5' } }} />
                        ) : null}
                      </Stack>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {jobs &&
                  jobs.content.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell sx={{ color: '#f79816' }}>
                        {row.scheduled && (
                          <LightTooltip
                            title={`Next Execution @ ${moment(row.nextTimeOfRun).format('MMMM Do YYYY, h:mm:ss a')}`}
                          >
                            <QueryBuilderIcon />
                          </LightTooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ cursor: 'pointer', color: '#699ad4' }} onClick={() => handleOpenModal(row)}>
                          {row.name}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textTransform: 'lowercase' }}>{ row.actionType==='WITHOUTSTUB' ? 'archive & delete' : row.actionType }</TableCell>
                      <TableCell>{row.agentName}</TableCell>
                      <TableCell>{row.policyName}</TableCell>
                      <TableCell>{showTarget(row)}</TableCell>
                      <TableCell>
                        <Label variant="ghost" color={statusColor(row)}>
                          {sentenceCase(row.jobStatus)}
                        </Label>
                      </TableCell>
                      <TableCell>
                        {row.jobStatus == 'PROCESSING' ? (
                          <Button variant="contained" sx={{backgroundColor:'#D22B2B	'}} onClick={() => jobRerun(row)}>
                            Terminate Job
                          </Button>
                        ) : (
                          <Button disabled={row?.jobStatus === 'TERMINATE'} variant="contained" onClick={() => jobRerun(row)}>
                            Rerun Job
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={jobs.totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
