import { Container, Grid, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { clearJobStatus, getExecutionDetails, getStatisticDetails, STATISTIC_FETCH_SUCCESS } from '../jobStatusActions';
import DetailTable from './DetailTable';

let initialRender = false;
const DetailsModal = ({ modalPopUp, jobDetails, jobRerun }) => {
  const [open, setOpen] = modalPopUp;
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const executionDetails = useSelector((state) => state.jobStatusReducer.executionDetails);
  const statisticDetails = useSelector((state) => state.jobStatusReducer.statisticDetails);
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    let interval;
    if (jobDetails) {
      dispatch(getExecutionDetails(jobDetails.id));
      // getStatisticDetailsFunc();
      interval = setInterval(() => {
        dispatch(getExecutionDetails(jobDetails.id));
         setCounter(c => c + 1)
      }, 10000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [jobDetails]);

  useEffect(() => {
    getStatisticDetailsFunc();
     
  }, [counter])

  useEffect(() => {
    if(executionDetails.length && !initialRender){
      getStatisticDetailsFunc();
      initialRender = true;
    }
     
  }, [executionDetails])

  const getStatisticDetailsFunc = () => {
    if(executionDetails.length){
      dispatch(
      getStatisticDetails({
        jobId: executionDetails[0].jobId,
        serialNumber: executionDetails[0].serialNumber,
        executionId: executionDetails[0].executionId,
      },executionDetails[0].id )
    );
  }
  }

  const executionColumns = ['Seq Id', 'Execution Id', 'Start Time', 'Time Taken', 'Status', 'Action'];
  const statisticColumns = [
    'Seq Id',
    'Evaluated',
    'Skipped',
    'Processed',
    'Duplicates',
    'Stub Queue',
    'Errored',
    'Size',
    'Throughput',
  ];

  const statisticSummary = (job) => {
    dispatch(getStatisticDetails({ jobId: job.jobId, serialNumber: job.serialNumber, executionId: job.executionId }, job.id));
  };
  const handleClose = () => {
    setOpen(false);
    dispatch({
      type: STATISTIC_FETCH_SUCCESS,
      payload: [],
    });
    dispatch(clearJobStatus())
    initialRender = false;
  };


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Page sx={{ width: '100%' }}>
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <DetailTable
                  executionColumns={executionColumns}
                  statisticColumns={statisticColumns}
                  executionDetails={executionDetails}
                  jobRerun={jobRerun}
                  jobDetails={jobDetails}
                  statisticSummary={statisticSummary}
                  statisticDetails={statisticDetails}
                  close={handleClose}
                />
              </Grid>
            </Grid>
          </Container>
        </Page>
      </Modal>
    </>
  );
};

export default DetailsModal;
